import styled from '@emotion/styled'

const Skeleton = styled.div`
  width: 30vw;
  max-width: 700px;
  height: 1.5rem;

  background: ${({ dark, theme }) =>
    dark ? theme.colors.primary : theme.colors.primaryLight};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 10.83333333333333vw;
    max-height: 8.666666666666667rem;
    margin: 0 auto;

    background: ${({ dark, theme }) =>
      dark ? theme.colors.primaryDark : theme.colors.primaryLightest};
  }
`

const Title = styled.h1`
  text-align: left;
  text-transform: uppercase;
  color: ${({ dark, theme }) =>
    dark ? theme.colors.primary : theme.colors.primaryLight};

  opacity: 0;
  transform: translate(0, 100%);
  transition: all 0.5s ease;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding-bottom: 2rem;

    text-align: center;
    font-size: 10.83333333333333vw;
    color: ${({ dark, theme }) =>
      dark ? theme.colors.primaryDark : theme.colors.primaryLightest};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-bottom: 3.2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    font-size: 8.666666666666667rem;
  }
`

const Description = styled.div`
  max-width: 800px;
  margin: 0 auto;

  color: ${({ dark, theme }) => (dark ? '#FFF' : theme.colors.primaryDarkest)};

  opacity: 0;
  transform: translate(0, 100%);
  transition: all 0.5s ease 0.5s;

  > p {
    margin-top: 1.125rem;

    letter-spacing: 0.82px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: -6vw auto 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largeDesktop}px) {
    margin: -94px auto 0;
  }
`

const Wrapper = styled.div`
  margin-top: ${({ isFirstElement }) => (isFirstElement ? '5.5rem' : '0')};
  margin-bottom: 2rem;
  padding: ${({ noPadding }) => (noPadding ? '0' : '0 1rem')};

  overflow: hidden;

  &.active ${Title}, &.active ${Description} {
    opacity: 1;
    transform: translate(0);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin-top: ${({ isFirstElement }) => (isFirstElement ? '10rem' : '0')};
    margin-bottom: 3rem;
  }
`

export { Skeleton, Wrapper, Title, Description }
