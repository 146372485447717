import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import 'intersection-observer'
import Observer from '@researchgate/react-intersection-observer'

// Styles
import { Skeleton, Wrapper, Title, Description } from './style'

const SectionHeader = ({
  title,
  description,
  overlap,
  dark,
  noPadding,
  isSectionTitle,
  isFirstElement
}) => {
  const [isAnimationStarted, setIsAnimationStarted] = useState(false)

  const handleIntersection = useCallback(
    event => {
      if (!isAnimationStarted && event.isIntersecting)
        setIsAnimationStarted(true)
    },
    [isAnimationStarted]
  )

  const options = {
    onChange: handleIntersection
  }

  return (
    <Observer {...options}>
      <Wrapper
        noPadding={noPadding}
        className={isAnimationStarted ? 'active' : ''}
        isFirstElement={isFirstElement}
      >
        {title ? (
          <Title dark={dark} as={isSectionTitle ? 'h2' : 'h1'}>
            {title}
          </Title>
        ) : (
          <Skeleton />
        )}
        {description && (
          <Description
            dark={dark}
            overlap={overlap}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Wrapper>
    </Observer>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  overlap: PropTypes.number,
  dark: PropTypes.bool,
  noPadding: PropTypes.bool,
  isSectionTitle: PropTypes.bool,
  isFirstElement: PropTypes.bool
}

SectionHeader.defaultProps = {
  description: '',
  overlap: 72,
  dark: false,
  noPadding: false,
  isSectionTitle: false,
  isFirstElement: false
}

export default SectionHeader
